import React from "react"
import { Link } from "gatsby"

const Footer = () => {

    const handleClick = e => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    };

    return (
        <footer className="footer background--footerBackground">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <Link to="/">
                            <img src={require('../assets/img/LogoLight.png')} alt="" className="footer__logo mb-40" />
                        </Link>
                        <p className="footer__adress-info">
                            <span>
                                Żółkiewskiego 7 Skavia Development <br />
                                Sp. z o.o. Sp. k.
                            </span>
                            ul. Marszałkowska 58 lok. 15<br />
                            00-545 Warszawa<br />
                            NIP: 7010957683
                        </p>
                    </div>
                    <div className="col-6 col-lg-2">
                        <div className="footer__menu">
                            <Link
                                to="/"	
                                className="menu__item"
                            >
                                Home
                            </Link>
                            <Link
                                to="/mieszkania/"	
                                className="menu__item"
                            >
                                Mieszkania
                            </Link>
                            <Link
                                to="/o-inwestycji/"	
                                className="menu__item"
                            >
                                O inwestycji
                            </Link>
                            <Link
                                to="/polityka-prywatnosci/"	
                                className="menu__item"
                            >
                                Polityka prywatności
                            </Link>
                        </div>
                    </div>
                    <div className="col-6 col-lg-2">
                        <div className="footer__menu footer__menu--right">
                            <Link
                                to="/lokalizacja/"	
                                className="menu__item"
                            >
                                Lokalizacja
                            </Link>
                            <Link
                                to="/dziennik-budowy/"	
                                className="menu__item"
                            >
                                Dziennik Budowy
                            </Link>
                            <Link
                                to="/kontakt/"	
                                className="menu__item"
                            >
                                Kontakt
                            </Link>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 footer__right-holder">
                        <button 
                            className="footer__to-top" 
                            aria-label="Scroll top"
                            onClick={() => handleClick()} 
                        />
                        <div className="footer__socials">
                            <a href="google.com/" className="socials__item">
                                <img src={require('../assets/img/facebook.png')} alt="facebook" className="item__icon"/>
                            </a>
                            <a href="google.com/" className="socials__item">
                                <img src={require('../assets/img/instagram.png')} alt="instagram" className="item__icon"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
    

export default Footer
