import React, { useState } from "react"
import { Link } from "gatsby"
import $ from "jquery"
import { useStaticQuery, graphql } from "gatsby"

const Header = () => {
	const [isMenuOpen, setMenu] = useState(false);

	const menuToogle = () => {
		const windowWidth = $(window).width();

		if(windowWidth < 992) {
			setMenu(!isMenuOpen);
	
			$('body').toggleClass('overflow-hidden');
		}
	}

	const AcfQuery = useStaticQuery(
		graphql`
		  query phone {
			allWordpressPage(filter: {title: {eq: "Strona główna"}}) {
				edges {
					node {
						acf {
							header_phone
						}
					}
				}
			}
		  }
		`
	)

	const data = AcfQuery.allWordpressPage.edges[0].node.acf;

	return (
		<header className="header" id="header">
			<div className="container">
				<div className="row">
					<div className="col-12 header__holder">
						<Link to="/">
							<img src={require('../assets/img/Logo.png')} alt="Logo" className="header__logo"/>
						</Link>
						<div className={`header__menu ${ isMenuOpen ? 'header__menu--open' : '' }`}>
							<a href={`tel:${data.header_phone}`} className="menu__phone">
								<img src={require('../assets/img/phoneIcon.png')} alt="" className="phone__icon"/>
								{data.header_phone}
							</a>
							<Link
								to="/"	
								className="menu__item"
								onClick={ () => menuToogle() }
							>
								Home
							</Link>
							<Link
								to="/mieszkania/"	
								className="menu__item"
								onClick={ () => menuToogle() }
								state={{
									fromSearch: false,
								}}
							>
								Mieszkania
							</Link>
							<Link
								to="/o-inwestycji/"	
								className="menu__item"
								onClick={ () => menuToogle() }
							>
								O inwestycji
							</Link>
							<Link
								to="/lokalizacja/"	
								className="menu__item"
								onClick={ () => menuToogle() }
							>
								Lokalizacja
							</Link>
							<Link
								to="/dziennik-budowy/"	
								className="menu__item"
								onClick={ () => menuToogle() }
							>
								Dziennik Budowy
							</Link>
							<Link
								to="/kontakt/"	
								className="menu__item"
								onClick={ () => menuToogle() }
							>
								Kontakt
							</Link>
						</div>
						<a href={`tel:${data.header_phone}`} className="header__phone">
							<img src={require('../assets/img/phoneIcon.png')} alt="" className="phone__icon"/>
							{data.header_phone}
						</a>
						<button className={`header__burger ${ isMenuOpen ? 'header__burger--open' : null }`} onClick={ () => menuToogle() }>
							<span></span>
							<span></span>
							<span></span>
						</button>
					</div>
				</div>
			</div>
		</header>
	)
}

export default Header
