import React, { Component } from "react";
import { CookiesProvider, withCookies } from "react-cookie";
import "../assets/styles/main.scss";

import Header from "./header";
import Footer from "./footer";
import SEO from "./seo";

class Layout extends Component {
  state = {
    cookieOpen: false,
  };

  componentDidMount() {
    const { cookies } = this.props;
    const isAcceptedCoookie = !!cookies.get("cookie-accept");
    !isAcceptedCoookie && this.setState({ cookieOpen: true });
  }

  acceptCookie = () => {
    const { cookies } = this.props;

    const promiseSetCookie = new Promise((resolve) =>
      resolve(cookies.set("cookie-accept", "active", { path: "/" }))
    );
    promiseSetCookie.then(() => {
      this.setState({ cookieOpen: false });
    });
  };

  render() {
    const { children, seoTitle, isHome } = this.props;
    return (
      <CookiesProvider>
        <SEO title={seoTitle} />
        <Header isHome={isHome} />
        <main className="main">{children}</main>
        <Footer isHome={isHome} />
        <div
          className={`cookie-baner${this.state.cookieOpen ? " open" : ""}`}
        >
          <p className="cookie-baner__text">
            Korzystamy z plików cookies w celu optymalizacji usług. Jeśli
            korzystasz z naszej witryny bez zmiany ustawień dotyczących cookies
            oznacza, że wyrażasz zgodę na zamieszczanie ich przez nas w Twoim
            urządzeniu.{" "}
          </p>
          <button 
            className="btn cookie-baner__btn" 
            onClick={this.acceptCookie}
          >
            Akceptuje
          </button>
        </div>
      </CookiesProvider>
    );
  }
}

export default withCookies(Layout);